import { useCallback, useEffect, useState, useTransition, Dimensions } from "react"
import { Card, Button } from "react-bootstrap"

import gitHubLogo from "../../logo/github_logo.png";
import linkedInLogo from "../../logo/linkedin_logo.png";

import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

export default function HomePage(props) {
  const [isHovered, setIsHovered] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
        setLoaded(true);
      }, 20);
  }, []);



  const steps = [
    {
      id: '0',
      message: "Hi!",
      trigger: "1"
    },
    {
      id: "1",
      message: "How can I help you?",
      trigger: "2"
    },
    {
      id: "2",
      options: [
        { value: 1, label: 'Test 1' },
        { value: 2, label: 'Test 2' }
      ],
      trigger: "3"
    },
    {
      id: "3",
      message: " {previousValue} "
    }
  ];

  const theme = {
    headerBgColor: '#575757',
    headerFontSize: '20px',
    botBubbleColor: '#363636',
    headerFontColor: 'white',
    botFontColor: 'white',
    userBubbleColor: '#363636',
    userFontColor: 'white'
  };


  const config = {
    floating: true,
  };

  return (
    <div className="App">
        <div style={{paddingLeft: "21%", paddingRight: "22.5%", paddingTop: "2.5%", alignItems: "center", lineHeight: "150%", font: "droid sans"}} className="Proj-Description">
            <p style={{paddingTop: "0.25%"}}>
                Hi! My name is Aditya Mehrotra. I am an undergraduate student at the University of Wisconsin-Madison majoring in Computer Science, Data Science, and Economics.
            </p>
            <div style={{paddingTop: "2.5%"}}>
              <Button variant="primary" style={{ opacity: loaded ? 1 : 0, transition: 'opacity 1s ease', width: "7.5%", height: "7.5%", marginRight: "1.5%", background: 'none', backgroundColor: "white", borderColor: (isHovered !== "github") ? "#bdbdbd" : "#828282" }}  onMouseEnter={() => setIsHovered("github")} onMouseLeave={() => setIsHovered("")} onClick={() => window.open("https://github.com/adityakmehrotra", '_blank', 'noopener,noreferrer')}>
                <img src={gitHubLogo} alt="GitHub Logo" style={{ opacity: (isHovered !== "github") ? "65%" : "80%", color: "#bdbdbd", width: '100%', height: '100%', marginRight: "1rem" }} />
              </Button>
              <Button variant="primary" style={{ opacity: loaded ? 1 : 0, transition: 'opacity 1.5s ease', width: "7.5%", height: "7.5%", background: 'none', backgroundColor: "white", borderColor: (isHovered !== "linkedin") ? "#bdbdbd" : "#828282" }}  onMouseEnter={() => setIsHovered("linkedin")} onMouseLeave={() => setIsHovered("")} onClick={() => window.open("https://www.linkedin.com/in/aditya-mehrotra-/", '_blank', 'noopener,noreferrer')}>
                <img src={linkedInLogo} alt="LinkedIn Logo" style={{ opacity: (isHovered !== "linkedin") ? "75%" : "90%", color: "#bdbdbd", width: '100%', height: '100%', marginRight: "1rem" }} />
              </Button>
            </div>
        </div>
        {
          /*
          <ThemeProvider theme={theme}>
            <ChatBot 
              headerTitle="Aditya Bot"
              steps={steps}
              {...config}/>
          </ThemeProvider>
          */
        }
    </div>
  );
}

