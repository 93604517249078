import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import Typewriter from "typewriter-effect";

function AppLayout(props) {
    const [mainText, setMainText] = useState("Aditya Mehrotra");
    const [isHovered, setIsHovered] = useState("");
    const [expanded, setExpanded] = useState(false);

    const updateText = (name = "Aditya Mehrotra") => {
        setMainText(name);
    };

    return (
        <div>
            <Navbar
                bg="white"
                expand="lg"
                variant="light"
                style={{ margin: 0, paddingTop: "5%", paddingLeft: "20%", paddingRight: "20%" }}
                className="d-flex align-items-center"
            >
                <Container className="d-flex justify-content-between align-items-center">
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        onClick={() => setMainText("Aditya Mehrotra")}
                        style={{
                            textDecoration: "none",
                            color: "black",
                            fontFamily: "sans-serif",
                            fontSize: "175%",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {mainText === "Aditya Mehrotra" ? (
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.deleteAll();
                                    typewriter.typeString(mainText).start();
                                }}
                            />
                        ) : (
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.deleteAll();
                                    typewriter.typeString(mainText).start();
                                }}
                            />
                        )}
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        style={{ border: "none", boxShadow: "none" }}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto" style={{ alignItems: "center" }}>
                            <Nav.Link
                                as={Link}
                                to="/coursework"
                                onClick={() => updateText("Coursework")}
                                style={{
                                    textDecoration: "none",
                                    color:
                                        isHovered === "coursework" || mainText === "Coursework"
                                            ? "black"
                                            : "#757575",
                                    marginRight: "1rem",
                                    fontFamily: "sans-serif",
                                    fontSize: "112.5%",
                                }}
                                onMouseEnter={() => setIsHovered("coursework")}
                                onMouseLeave={() => setIsHovered("")}
                            >
                                coursework
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/projects"
                                onClick={() => updateText("Projects")}
                                style={{
                                    textDecoration: "none",
                                    color:
                                        isHovered === "projects" || mainText === "Projects"
                                            ? "black"
                                            : "#757575",
                                    marginRight: "1rem",
                                    fontFamily: "sans-serif",
                                    fontSize: "112.5%",
                                }}
                                onMouseEnter={() => setIsHovered("projects")}
                                onMouseLeave={() => setIsHovered("")}
                            >
                                projects
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/experience"
                                onClick={() => updateText("Experience")}
                                style={{
                                    textDecoration: "none",
                                    color:
                                        isHovered === "experience" || mainText === "Experience"
                                            ? "black"
                                            : "#757575",
                                    marginRight: "1rem",
                                    fontFamily: "sans-serif",
                                    fontSize: "112.5%",
                                }}
                                onMouseEnter={() => setIsHovered("experience")}
                                onMouseLeave={() => setIsHovered("")}
                            >
                                experience
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/papers"
                                onClick={() => updateText("Papers")}
                                style={{
                                    textDecoration: "none",
                                    color:
                                        isHovered === "papers" || mainText === "Papers"
                                            ? "black"
                                            : "#757575",
                                    marginRight: "1rem",
                                    fontFamily: "sans-serif",
                                    fontSize: "112.5%",
                                }}
                                onMouseEnter={() => setIsHovered("papers")}
                                onMouseLeave={() => setIsHovered("")}
                            >
                                research
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Outlet for rendering child routes */}
            <Outlet />
        </div>
    );
}

export default AppLayout;
