import { useCallback, useEffect, useState, Text } from "react"
import ExperienceCard from './ExperienceCard'


 const Experience = () => {

    const [experienceList, setExperienceList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setExperienceList(prevList => [
            { 
                index: 0,
                title: "DevOps Engineer Intern", 
                description: "I am automating CI/CD pipelines and system integrations, achieving a 25% reduction in deployment times while maintaining 99.9% system uptime in a high-availability environment. I also develop Python and YAML scripts to enhance pipeline efficiency, a leverage Azure DevOps to test and refine job performance.", 
                dates: "Sept 2024 - Present",
                company: "AbbVie", },
            { 
                index: 1,
                title: "Software Developer Intern", 
                description: "I worked as a full-stack software developer on an Agile Scrum team, building an application within a scalable microservice architecture to generate complementary orders for the top 2,000 customers. In this role I used Spring Boot (Java) for the backend, Angular and TypeScript for the frontend, and NoSQL databases. I also developed JUnit and Postman tests to validate code reliability, and used Git for version control.", 
                dates: "May 2024 - Aug 2024",
                company: "Uline", },
            { 
                index: 2,
                title: "President, Lead Engineer", 
                description: "I oversee the strategic direction and operational management of the organization, fostering a collaborative environment for members to explore cutting-edge financial technologies. I lead technical projects, mentor teams on developing innovative solutions in areas like blockchain and algorithmic trading, and organize workshops and speaker sessions to enhance technical and professional skills.", 
                dates: "Jan 2023 - Present",
                company: "UW-Madison FinTech Club" },
            { 
                index: 3,
                title: "Software Engineer Intern", 
                description: "I helped develop a GBM-based machine learning model that improved fraud detection by 25%, using data (transaction history, user behavior, and more). Additionally, I designed and implemented a React and .NET-based dashboard to analyze workflows, reducing processing times by 40%.", 
                dates: "May 2023 - Aug 2023",
                company: "UW Credit Union", },
            { 
                index: 4,
                title: "Founder, Software Developer", 
                description: "I create custom websites and applications that help local businesses establish and enhance their online presence. With a focus on full-stack development, I deliver products that meet the needs of my clients.", 
                tags: ["website"], 
                dates: "Apr 2023 - Present",
                company: "Developer Secure", 
                url: "https://www.developersecure.com" },
        ]);

    }, []);
    
    return (
        <div className="App" style={{paddingLeft: "21%", paddingRight: "22.5%", paddingTop: "0.25%", font: "droid sans"}}>
            {experienceList?.map((experience, index) => (
                <ExperienceCard key={index} experience={experience}>hi</ExperienceCard>
            ))}
        </div>
    );
}

export default Experience;

