import { useCallback, useEffect, useState } from "react"
import { Card, Button, Image } from "react-bootstrap"

import ulineHubLogo from "../../../logo/uline_logo.png";

export default function Uline(props) {



  return (
    <div className="App">
        <div style={{paddingLeft: "21%", paddingRight: "22.5%", paddingTop: "0.5%", alignItems: "center", lineHeight: "150%"}} className="Proj-Description">
            <hr />
            <h1 style={{paddingTop: "0.5%"}}>Uline</h1>
            <a href="https://www.uline.com/" target="_blank" style={{textDecoration: "none"}}>
              <Image src={ulineHubLogo} alt="Uline Logo" style={{width: '30%', float: "right", height: '30%', marginTop: "-3.3rem", marginRight: "0.5rem" }}></Image>
            </a>
            <h4 style={{paddingTop: "0.5%", color: "gray"}}>Software Developer Intern</h4>
            <p style={{ paddingTop: "5%", display: "inline", margin: 0, fontSize: 16, color: "gray"}}>May 2024 - Aug 2024</p>
            <p style={{paddingTop: "2.5%"}}>
              During my internship at Uline, I have the privilege of contributing to the Enterprise Team within the IT department, working as part of an Agile Scrum team focused on full-stack development. My role involves enhancing user interfaces using Angular and TypeScript for the frontend, while implementing server-side solutions with Spring Boot and managing databases with DBeaver on the backend.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              In the early stages of the development cycle, I actively participated in backlog grooming sessions where I helped draft user stories and identify potential technical spikes, areas of technical debt, and research and development needs. This process involved providing initial estimates and story points, which were crucial for shaping our development strategies. During sprint planning, I engaged thoroughly with the product backlog to understand each user story fully, contributing to high-level design discussions and proposing effective delivery approaches. I refined story points, outlined tasks for each story, and helped define clear and measurable acceptance criteria.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              Throughout each sprint cycle, I diligently completed all assigned tasks and stories, updating our story board and tracking hours needed for each task to manage the team’s burn rate effectively. I ensured that all completed stories adhered strictly to our Definition of Done (DoD), maintaining high standards of quality. Additionally, I conducted "desk checks" with Solution Architects and Quality Assurance teams to validate and refine our work.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              At the end of each sprint, I demonstrated the completed work to stakeholders, addressing their questions and integrating their feedback for future development efforts. I also participated actively in Sprint Retrospectives, offering insights and suggestions to enhance our team's efficiency and effectiveness. This internship not only allows me to deepen my technical skills but also greatly enhanced my understanding of Agile methodologies and team collaboration in a dynamic enterprise environment.
            </p>
        </div>
    </div>
  );
}

