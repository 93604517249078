import { useCallback, useEffect, useState } from "react"
import { Card, Button, Image } from "react-bootstrap"

import uwftLogo from "../../../logo/uwft_logo.png";

export default function Abbvie(props) {



  return (
    <div className="App">
        <div style={{paddingLeft: "21%", paddingRight: "22.5%", paddingTop: "0.5%", alignItems: "center", lineHeight: "150%"}} className="Proj-Description">
            <hr />
            <h1 style={{paddingTop: "0.5%"}}>UW-Madison FinTech Club</h1>
            <a href="https://www.abbvie.com/" target="_blank" style={{textDecoration: "none"}}>
              <Image src={uwftLogo} alt="AbbVie Logo" style={{width: '20%', float: "right", height: '20%', marginTop: "-3.3rem", marginRight: "0.5rem" }}></Image>
            </a>
            <h4 style={{paddingTop: "0.5%", color: "gray"}}>President, Lead Engineer</h4>
            <p style={{ paddingTop: "5%", display: "inline", margin: 0, fontSize: 16, color: "gray"}}>Oct 2024 - Dec 2024</p>
            <p style={{paddingTop: "2.5%"}}>
              I am currently contributing to a project within the Enterprise DevOps team focused on building automated CI/CD pipelines and enhancing system integrations. My role involves designing and implementing self-service solutions that allow teams to autonomously manage system provisioning and streamline deployment processes, contributing to an efficient and scalable infrastructure.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              I work with an Agile team to develop automation artifacts, including Python and Bash scripts, that support various stages of the deployment lifecycle. By utilizing tools such as Jenkins and Docker, I automate pipeline tasks that optimize deployment speed and maintain high system uptime, improving our operational efficiency in a high-availability environment. I also configure and manage Kubernetes clusters on AWS, ensuring that our infrastructure is designed to handle scalability and performance demands effectively.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              I collaborate with DevOps engineers to develop and deploy code, incorporating Infrastructure as Code (IaC) principles to maintain consistency across environments. I am responsible for creating YAML-based CI/CD configurations and troubleshooting system integration issues to enhance the user experience and reduce latency in deployments. I regularly leverage Git for version control, collaborating on code reviews and refining workflows to ensure our coding standards align with industry best practices. In addition, I work on orchestrating APIs and integrating automated workflows to further our self-service goals, allowing teams to perform tasks without direct manual input.
            </p>
        </div>
    </div>
  );
}

