import { useCallback, useEffect, useState, Text } from "react"
import PaperCard from './PaperCard'


 const Papers = () => {

    const [papersList, setPapersList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setPapersList(prevList => [
            { 
                index: 0,
                title: "GPU Optimization for OLAP Databases", 
                dates: "Nov 2024 - Present",
                company: "Researching techniques that can fully utilize the power of GPUs in Online Analytical Processing (OLAP) databases by optimizing GPU computational capabilities, memory capacity, bandwidth, and PCIe connectivity.",
                tags: ["Prof. Xiangyao Hu"],
                url: "",
                sample: "https://pages.cs.wisc.edu/~yxy/"},
            { 
                index: 2,
                title: "Stress Detection with Wearable Technology", 
                company: "Researched the use of wearable technologies to detect stress in users by analyzing data from multiple sensors (heart rate monitors, galvanic skin response sensors, accelerometers, and temperature sensors), to identify physiological and behavioral stress indicators.", 
                dates: "May 2022 - Jul 2024",
                tags: ["paper"],
                url: ""
            },
            { 
                index: 1,
                title: "Economic Impact of Technological Innovations", 
                company: "Researched the impact of major technological innovations on key macroeconomic indicators (CPI, GDP, unemployment rates, labor productivity, etc.), comparing BRICS countries with other developed nations.", 
                tags: ["paper"], 
                dates: "Jan 2024 - May 2024",
                url: "" }
        ]);

    }, []);
    
    return (
        <div className="App" style={{paddingLeft: "21%", paddingRight: "22.5%", paddingTop: "0.25%", font: "droid sans"}}>
            {papersList?.map((experience, index) => (
                <PaperCard key={index} research={experience}>hi</PaperCard>
            ))}
        </div>
    );
}

export default Papers;

