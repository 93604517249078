import React from "react"
import { useState, useEffect, useContext} from "react"
import { Card, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';



function PaperCard({research}) {
    const [isHovered, setIsHovered] = useState(false);
    const [isTagHovered, setIsTagHovered] = useState(false);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
          }, 20);
    }, []);


    console.log("props")
    console.log(research)

    const pressButton = (research) => {
        console.log("exdsfjnsdfsd");
        console.log(research.title);
        if (research.company === 'Uline') {
            console.log("dlkjfkds")
            navigate('/papers/economicmlpaper');
        } else if (research.company === 'UW Credit Union') {
            // navigate('/research/uwcu');
        } else if (research.company === 'Developer Secure') {
            // window.open(research.url, '_blank', 'noopener,noreferrer');
        }
    }

    return (
        <Card
            style={{opacity: loaded ? 1 : 0, transition: 'opacity ' + research.index * 0.75 + 's ease', marginTop: "1rem", marginBottom: "1rem", padding: "0.5rem", height: "100%", backgroundColor: isHovered ? "#f0f0f0" : "transparent", font: "droid sans", border: "none"}} 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
            <Card.Body>
                <a 
                href={research.url} style={{textDecoration: "none", color: "black"}} onClick={() => pressButton(research)}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                        <div style={{ display: "flex", alignItems: "center"}}>
                            <p style={{ display: "inline", color: "#fc7f03", fontWeight: "bold", margin: 0, fontSize: 22}}>#</p>
                            <p style={{ display: "inline", fontWeight: "bold", margin: 0, paddingLeft: "5px", fontSize: 18}}>{research.title}</p>
                        </div>
                        <p style={{ display: "inline", margin: 0, fontSize: 16, color: "gray"}}>{research.dates}</p>
                    </div>
                    <p style={{ paddingTop: "1%", }}>{research.company}</p>
                    <div>
                        {research.tags && research.tags.map((tag, index) => (
                            <span key={index} style={{ display: "inline-block", color: "red", margin: 0, fontSize: 14, marginRight: "20px" }}>
                                <a href={null} target="_blank" style={{display: "inline-block", color: (isTagHovered === false) ? "#fc7f03" : "#c16102", textDecoration: "none"}}onMouseEnter={() => setIsTagHovered(true)} onMouseLeave={() => setIsTagHovered(false)}>   
                                    {tag} 
                                </a> 
                            <span style={{ margin: "0.4rem" }}></span></span>
                        ))}
                    </div>
                </a>
            </Card.Body>
        </Card>
    );
}

export default PaperCard;