import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppLayout from './AppLayout';
import HomePage from './Pages/Page 1/HomePage';
import Coursework from './Pages/Page 2/Coursework';
import CS577_Project from './Pages/Page 2/Courses/CS577_Project';
import CS400_Project from './Pages/Page 2/Courses/CS400_Project';
import CS320_Notes from './Pages/Page 2/Courses/CS320_Notes';
import CS320_Project from './Pages/Page 2/Courses/CS320_Project';
import CS300_Notes from './Pages/Page 2/Courses/CS300_Notes';
import CS300_Project from './Pages/Page 2/Courses/CS300_Project';
import STAT340_Notes from './Pages/Page 2/Courses/STAT340_Notes';
import Projects from './Pages/Page 3/Projects';
import Experience from './Pages/Page 4/Experience';
import Abbvie from './Pages/Page 4/Experiences/Abbvie';
import Uline from './Pages/Page 4/Experiences/Uline';
import Uwcu from './Pages/Page 4/Experiences/Uwcu';
import Uwft from './Pages/Page 4/Experiences/UW-Madison_FinTech_Club';
import Papers from './Pages/Page 5/Papers';
import EconomicMLPaper from './Pages/Page 5/Paper/EconomicMLPaper';

export default function Navigator() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AppLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/coursework" element={<Coursework />} />
                    <Route path="/coursework/CS577/project" element={<CS577_Project />} />
                    <Route path="/coursework/CS400/project" element={<CS400_Project />} />
                    <Route path="/coursework/CS320/notes" element={<CS320_Notes />} />
                    <Route path="/coursework/CS320/project" element={<CS320_Project />} />
                    <Route path="/coursework/CS300/notes" element={<CS300_Notes />} />
                    <Route path="/coursework/CS300/project" element={<CS300_Project />} />
                    <Route path="/coursework/STAT340/notes" element={<STAT340_Notes />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/experience" element={<Experience />} />
                    <Route path="/experience/abbvie" element={<Abbvie />} />
                    <Route path="/experience/uline" element={<Uline />} />
                    <Route path="/experience/uwcu" element={<Uwcu />} />
                    <Route path="/experience/uw-madison_fintech_club" element={<Uwft />} />
                    <Route path="/papers" element={<Papers />} />
                    <Route path="/papers/economicmlpaper" element={<EconomicMLPaper />} />
                    <Route path="*" element={<HomePage />} />
                </Route>
            </Routes>
        </BrowserRouter>
  )
}